import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllDealsHandler, updateDealStatusById, updateDealMortgageByIdHandler, updateDealRealizedByIdHandler, cloneDealByIdHandler } from "./deal.thunk";
import { toast } from "react-toastify";

const INIT_STATE = {
    deals:[],
    meta:{}
}

export const fetchAllDealsAsyncThunk = createAsyncThunk(
    "fetchDeals",
    fetchAllDealsHandler
);

export const updateDealByIdThunk = createAsyncThunk(
    "updateDealById",
    updateDealStatusById
)

export const updateDealMortgageByIdThunk = createAsyncThunk(
    "updateDealMortgageById",
    updateDealMortgageByIdHandler
)

export const updateDealRealizedByIdThunk = createAsyncThunk(
    "updateDealRealizedById",
    updateDealRealizedByIdHandler
)

export const cloneDealByIdThunk = createAsyncThunk(
    "cloneDealById",
    cloneDealByIdHandler
)

const DealSlice = createSlice({
    name:"Deal",
    initialState:INIT_STATE,
    reducers:{
        setDeals:(state,action)=>{
            state.deals = action.payload;
        }
    },
    extraReducers:(builder)=>{
        builder.
        addCase(fetchAllDealsAsyncThunk.pending,(state)=>{
            return state;
        })
        .addCase(fetchAllDealsAsyncThunk.fulfilled,(state,action)=>{
            return action?.payload?.data;
        })
        .addCase(fetchAllDealsAsyncThunk.rejected,(state)=>{
            toast.error("Fetching deals failed");
            return state;
        })
        .addCase(updateDealByIdThunk.pending,(state)=>{
            return state;
        })
        .addCase(updateDealByIdThunk.fulfilled,(state,action)=>{
            const newState = JSON.parse(JSON.stringify({...state}));

            if(newState?.deals?.findIndex(el=>el._id==action?.payload?.data?._id)!==-1){
                newState.deals[newState?.deals?.findIndex(el=>el._id==action?.payload?.data?._id)].status = action?.payload?.data?.status;
            }   
            toast.success("Deal updated succesfully");
            return newState;
        })
        .addCase(updateDealByIdThunk.rejected,(state)=>{
            toast.error("Deal updation failed");
            return state;
        })
        .addCase(updateDealMortgageByIdThunk.pending,(state)=>{
            return state;
        })
        .addCase(updateDealMortgageByIdThunk.fulfilled,(state,action)=>{
            const newState = JSON.parse(JSON.stringify({...state}));

            if(newState?.deals?.findIndex(el=>el._id==action?.payload?.data?._id)!==-1){
                newState.deals[newState?.deals?.findIndex(el=>el._id==action?.payload?.data?._id)].mortgage = action?.payload?.data?.mortgage;
                if(action?.payload?.data?.mortgage) newState.meta.mortgage_count+=1;
                else newState.meta.mortgage_count-=1;
            }   
            toast.success("Deal updated succesfully");
            return newState;
        })
        .addCase(updateDealMortgageByIdThunk.rejected,(state)=>{
            toast.error("Deal updation failed");
            return state;
        })
        .addCase(updateDealRealizedByIdThunk.pending,(state)=>{
            return state;
        })
        .addCase(updateDealRealizedByIdThunk.fulfilled,(state,action)=>{
            const newState = JSON.parse(JSON.stringify({...state}));
            if(newState?.deals?.findIndex(el=>el._id==action?.payload?.data?._id)!==-1){
                newState.deals[newState?.deals?.findIndex(el=>el._id==action?.payload?.data?._id)].realized = action?.payload?.data?.realized;
            }   
            toast.success("Deal updated succesfully");
            return newState;
        })
        .addCase(updateDealRealizedByIdThunk.rejected,(state)=>{
            toast.error("Deal updation failed");
            return state;
        })
        .addCase(cloneDealByIdThunk.pending,(state)=>{
            return state;
        })
        .addCase(cloneDealByIdThunk.fulfilled,(state,action)=>{
            const newState = JSON.parse(JSON.stringify({...state}));
            newState.deals.push(action?.payload?.data); 
            toast.success("Deal updated succesfully");
            return newState;
        })
        .addCase(cloneDealByIdThunk.rejected,(state)=>{
            toast.error("Deal updation failed");
            return state;
        })
    }
})

export const {setDeals} = DealSlice.actions;

export default DealSlice.reducer;